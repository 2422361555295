import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../axios/global";
import EventList from "../components/EventList";
import { Select, Button, Modal, Tabs, Image, Grid, Table } from "@mantine/core";
import { Prism } from "@mantine/prism";
import { useForm } from "@mantine/form";
import Papa from "papaparse";
import exp from "../assets/export.svg";
import up from "../assets/up.svg";
import down from "../assets/down.svg";
import more from "../assets/expand-more.svg";
import less from "../assets/show-less.svg";
import inprocess from "../assets/inprocess.svg";
import toconfig from "../assets/to-configure.svg";
import inwaiting from "../assets/inwaiting.svg";
import { toast } from "sonner";

const TrackingPlan = () => {
  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectEvents, setProjectEvents] = useState([]);
  const [visibleEventId, setVisibleEventId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTab, setSelectedTab] = useState("informations");
  const [showAll, setShowAll] = useState(false);
  const [recipeData, setRecipeData] = useState({
    date: "",
    status: "",
    comment: "",
  });

  const form = useForm({
    initialValues: {
      status: "",
    },
    validate: {
      status: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
    },
  });

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        setLoading(true);
        const res = await instance.get(`/project/${id}`);
        setProjectDetails(res?.data);
        console.log(res.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails du projet",
          error
        );
        setLoading(false);
      }
    };

    const fetchProjectEventsWithParametersAndImages = async () => {
      try {
        setLoading(true);
        const res = await instance.get(
          `/event/projectEventWithParameters/${id}`
        );
        const events = res?.data;
        console.log(events);
        setProjectEvents(events);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des événements du projet",
          error
        );
        setLoading(false);
      }
    };

    fetchProjectDetails();
    fetchProjectEventsWithParametersAndImages();
  }, [id]);

  const exportCSV = (events) => {
    const csv = Papa.unparse(events);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "events.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  const handleStatusChange = async (eventId, newStatus) => {
    try {
      await instance.put(`/event/updateStatus/${eventId}`, {
        status: newStatus,
      });
      setProjectEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId ? { ...event, status: newStatus } : event
        )
      );
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du statut de l'événement :",
        error
      );
    }
  };

  const toggleVisibility = (eventId) => {
    setVisibleEventId((prevId) => (prevId === eventId ? null : eventId));
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const getFilteredEvents = () => {
    if (selectedTab === "all") {
      return projectEvents.filter((event) => event.datalayer); // Ne garder que les événements avec un datalayer non null
    }
    return projectEvents.filter(
      (event) => event.tags.includes(selectedTab) && event.datalayer
    ); // Filtrer par tag et datalayer
  };

  const filteredEvents = getFilteredEvents();

  const formatDate = (isoDate) => {
    if (!isoDate) return ""; // Si isoDate est null ou undefined, retourne une chaîne vide

    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // const generateCSVData = (events) => {
  //   const csvData = events.map((event) => ({
  //     "Nom de l'événement": event.name,
  //     Tags: Array.isArray(event.tags) ? event.tags.join(", ") : event.tags, // Vérification et traitement de event.tags
  //     Déclencheur: event.trigger,
  //     Description: event.description,
  //     //"Nom du paramètre": event.parameters.map(param => param.parameterName).join('; '),
  //     //"Type": event.parameters.map(param => param.datatype).join('; '),
  //     //"Valeur par défaut": event.parameters.map(param => param.value).join('; '),
  //     "sur quel URL peut-on tester le code": event.url_test,
  //     "sur quel page/template le datalayer est implementé":
  //       event.page_datalayer,
  //     Commentaire: event.comments,
  //     Datalayer: event.datalayer || "",
  //   }));

  //   return csvData;
  // };

  // const handleExport = () => {
  //   const csvData = generateCSVData(filteredEvents);
  //   exportCSV(csvData);
  // };

  const copyUrlToClipboard = async () => {
    try {
      const currentUrl = window.location.href;
      // Replace 'plan-tracking' with 'public-plan-tracking' in the URL
      const publicUrl = currentUrl.replace(
        "/plan-tracking/",
        "/public-plan-tracking/"
      );

      await navigator.clipboard.writeText(publicUrl); // Copy the public URL to the clipboard
      toast.success("URL publique copiée !", { sound: true }); // Use sonner with sound feedback
    } catch (error) {
      console.error("Erreur lors de la copie de l'URL :", error);
      toast.error("Erreur lors de la copie de l'URL.");
    }
  };

  const ProjectStatus = ({ status }) => {
    // Définir un mapping pour associer chaque statut à une image
    const statusToImageMap = {
      Configuré: up,
      Cloturé: down,
      "En cours d'implementation": inprocess,
      "À configurer": toconfig,
      "En attente": inwaiting,
    };

    // Déterminer l'image à afficher en fonction du statut
    const imageSrc = statusToImageMap[status];

    // Définir une classe CSS en fonction du statut (facultatif)
    let statusClass = "";
    switch (status) {
      case "En cours d'implementation":
        statusClass = "status-en-cours";
        break;
      case "Configuré":
        statusClass = "status-configure";
        break;
      case "À configurer":
        statusClass = "status-to-configure";
        break;
      case "Cloturé":
        statusClass = "status-cloture";
        break;
      case "En attente":
        statusClass = "status-en-attente";
      default:
        statusClass = "";
    }

    return (
      <div className={`status-container ${statusClass}`}>
        <img src={imageSrc} alt={`${status} icon`} className="status-icon" />
        <div style={{ marginLeft: "14px" }}>
          <h1>
            {projectDetails?.projectName || "Nom du projet non disponible"}
          </h1>
          <div className="status-updateat">
            <p className={statusClass}>{status}</p>
            <h4>
              Dernière modification le {formatDate(projectDetails?.updatedAt)}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const renderCustomFields = () => {
    if (Array.isArray(projectDetails?.custom_fields)) {
      return projectDetails.custom_fields.map((field, index) => (
        <div style={{ marginTop: "1rem" }} key={index} span={4}>
          <p className="title">{field.key}</p>
          <p className="infos">
            {typeof field.value === "string"
              ? field.value.split("\n").map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              : JSON.stringify(field.value, null, 2)}{" "}
          </p>
        </div>
      ));
    }
    return <p className="infos"></p>;
  };

  return (
    <div className="page-tracking-plan">
      <div className="container-title-tracking">
        <span>
          <ProjectStatus status={projectDetails?.status} />
        </span>
        <div className="container-button-title">
          <Button size="xs" color="indigo" onClick={copyUrlToClipboard}>
            Partager
          </Button>
          {/* <Button size="xs" color="indigo" onClick={handleExport}>
            <img src={exp} alt="export"/>Export
          </Button> */}
        </div>
      </div>

      {loading ? (
        <p>Chargement des événements en cours...</p>
      ) : filteredEvents.length > 0 ? (
        <div>
          <Tabs color="gray" value={selectedTab} onTabChange={setSelectedTab}>
            <Tabs.List>
              <Tabs.Tab value="informations">Informations du projet</Tabs.Tab>
              <Tabs.Tab value="all">All Events</Tabs.Tab>
              <Tabs.Tab value="standard">Standard events</Tabs.Tab>
              <Tabs.Tab value="interaction">Interaction events</Tabs.Tab>
              <Tabs.Tab value="conversion">Conversion events</Tabs.Tab>
              <Tabs.Tab value="e-commerce">E-commerce events</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="informations" pt="xs">
              <div className="container-grid">
                <Grid className="grid" grow gutter="sm">
                  <Grid.Col className="grid-col" span={3}>
                    <p className="title">Plateforme</p>
                    <p className="info">
                      {projectDetails?.platform_type || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={5}>
                    <p className="title">Url</p>
                    <p className="info">
                      {projectDetails?.projectUrl || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Secteur</p>
                    <p className="info">
                      {projectDetails?.activity_area || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Pays</p>
                    <p className="info">
                      {projectDetails?.country || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Technologie</p>
                    <p className="info">
                      {projectDetails?.technology || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={3}>
                    <p className="title">Analytics Plateforme</p>
                    <p className="info">
                      {projectDetails?.analytics_platform || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={5}>
                    <p className="title">Tag Manager</p>
                    <p className="info">
                      {projectDetails?.encapsulator || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={6}>
                    <p className="title">Informations</p>
                    <p className="infos">
                      {projectDetails?.information || "Non disponible"}
                    </p>
                  </Grid.Col>
                </Grid>
              </div>
            </Tabs.Panel>

            <Tabs.Panel value="all" pt="xs">
              <EventList
                events={filteredEvents}
                handleStatusChange={handleStatusChange}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
                showAll={showAll}
                setShowAll={setShowAll}
                setProjectEvents={setProjectEvents}
              />
            </Tabs.Panel>

            <Tabs.Panel value="standard" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("standard")
                )}
                handleStatusChange={handleStatusChange}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
                showAll={showAll}
                setShowAll={setShowAll}
                setProjectEvents={setProjectEvents}
              />
            </Tabs.Panel>

            <Tabs.Panel value="interaction" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("interaction")
                )}
                handleStatusChange={handleStatusChange}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
                showAll={showAll}
                setShowAll={setShowAll}
                setProjectEvents={setProjectEvents}
              />
            </Tabs.Panel>

            <Tabs.Panel value="conversion" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("conversion")
                )}
                handleStatusChange={handleStatusChange}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
                showAll={showAll}
                setShowAll={setShowAll}
                setProjectEvents={setProjectEvents}
              />
            </Tabs.Panel>

            <Tabs.Panel value="e-commerce" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("e-commerce")
                )}
                handleStatusChange={handleStatusChange}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
                showAll={showAll}
                setShowAll={setShowAll}
                setProjectEvents={setProjectEvents}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      ) : (
        <div>
          <Tabs color="gray" value={selectedTab} onTabChange={setSelectedTab}>
            <Tabs.List>
              <Tabs.Tab value="informations">Informations du projet</Tabs.Tab>
              <Tabs.Tab value="all">All Events</Tabs.Tab>
              <Tabs.Tab value="standard">Standard events</Tabs.Tab>
              <Tabs.Tab value="interaction">Interaction events</Tabs.Tab>
              <Tabs.Tab value="conversion">Conversion events</Tabs.Tab>
              <Tabs.Tab value="e-commerce">E-commerce events</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="informations" pt="xs">
              <div className="container-grid">
                <Grid className="grid" grow gutter="sm">
                  <Grid.Col className="grid-col" span={3}>
                    <p className="title">Plateforme</p>
                    <p className="info">
                      {projectDetails?.platform_type || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={5}>
                    <p className="title">Url</p>
                    <p className="info">
                      {projectDetails?.projectUrl || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Secteur</p>
                    <p className="info">
                      {projectDetails?.activity_area || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Pays</p>
                    <p className="info">
                      {projectDetails?.country || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={1}>
                    <p className="title">Technologie</p>
                    <p className="info">
                      {projectDetails?.technology || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Analytics Plateforme</p>
                    <p className="info">
                      {projectDetails?.analytics_platform || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Tag Manager</p>
                    <p className="info">
                      {projectDetails?.encapsulator || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={8}>
                    <p className="title">Informations</p>
                    <p className="infos">
                      {projectDetails?.information || "Non disponible"}
                    </p>
                    {renderCustomFields()}
                  </Grid.Col>
                </Grid>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="all" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="standard" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event standard configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="interaction" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event interaction configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="conversion" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event conversion configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="e-commerce" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event e-commerce configuré
              </p>
            </Tabs.Panel>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default TrackingPlan;
