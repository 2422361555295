import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../axios/global";
import { Prism } from "@mantine/prism";
import { Tabs, Modal, Image, Grid, Select, Badge } from "@mantine/core";
import more from "../assets/expand-more.svg";
import less from "../assets/show-less.svg";

const PublicTrackingPlan = () => {
  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectEvents, setProjectEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleEventId, setVisibleEventId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTab, setSelectedTab] = useState("informations");

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        setLoading(true);
        const res = await instance.get(`/project/${id}`);
        setProjectDetails(res.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails du projet",
          error
        );
        setLoading(false);
      }
    };

    const fetchProjectEventsWithParametersAndImages = async () => {
      try {
        setLoading(true);
        const res = await instance.get(
          `/event/projectEventWithParameters/${id}`
        );
        const events = res?.data;
        console.log(events);
        setProjectEvents(events);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des événements du projet",
          error
        );
        setLoading(false);
      }
    };

    fetchProjectDetails();
    fetchProjectEventsWithParametersAndImages();
  }, [id]);

  const getFilteredEvents = () => {
    if (selectedTab === "all") {
      return projectEvents.filter((event) => event.datalayer); // Ne garder que les événements avec un datalayer non null
    }
    return projectEvents.filter(
      (event) => event.tags.includes(selectedTab) && event.datalayer
    ); // Filtrer par tag et datalayer
  };

  const filteredEvents = getFilteredEvents();

  //   const exportCSV = (events) => {
  //     const csv = Papa.unparse(events);
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", "events.csv");
  //     document.body.appendChild(link); // Required for FF
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  //   const generateCSVData = (events) => {
  //     const csvData = events.map((event) => ({
  //       "Nom de l'événement": event.name,
  //       Tags: Array.isArray(event.tags) ? event.tags.join(", ") : event.tags, // Vérification et traitement de event.tags
  //       Déclencheur: event.trigger,
  //       Description: event.description,
  //       //"Nom du paramètre": event.parameters.map(param => param.parameterName).join('; '),
  //       //"Type": event.parameters.map(param => param.datatype).join('; '),
  //       //"Valeur par défaut": event.parameters.map(param => param.value).join('; '),
  //       "sur quel URL peut-on tester le code": event.url_test,
  //       "sur quel page/template le datalayer est implementé":
  //         event.page_datalayer,
  //       Commentaire: event.comments,
  //       Datalayer: event.datalayer || "",
  //     }));

  //     return csvData;
  //   };

  //   const handleExport = () => {
  //     const csvData = generateCSVData(filteredEvents);
  //     exportCSV(csvData);
  //   };

  const toggleVisibility = (eventId) => {
    setVisibleEventId((prevId) => (prevId === eventId ? null : eventId));
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const renderCustomFields = () => {
    if (Array.isArray(projectDetails?.custom_fields)) {
      return projectDetails.custom_fields.map((field, index) => (
        <div style={{ marginTop: "1rem" }} key={index} span={4}>
          <p className="title">{field.key}</p>
          <p className="infos">
            {typeof field.value === "string"
              ? field.value.split("\n").map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              : JSON.stringify(field.value, null, 2)}{" "}
          </p>
        </div>
      ));
    }
    return <p className="infos"></p>;
  };

  return (
    <div className="page-tracking-plan" style={{ paddingTop: "5rem" }}>
      <div className="container-title-tracking">
        <div className="title-tracking">
          <h1>{projectDetails?.projectName}</h1>
        </div>
        <div className="container-button-title">
          {/*<button onClick={handleExport}><img src={exp} alt="export"/>Export</button>*/}
        </div>
      </div>

      {loading ? (
        <p>Chargement des événements en cours...</p>
      ) : filteredEvents.length > 0 ? (
        <div>
          <Tabs color="gray" value={selectedTab} onTabChange={setSelectedTab}>
            <Tabs.List>
              <Tabs.Tab value="informations">Informations du projet</Tabs.Tab>
              <Tabs.Tab value="all">All Events</Tabs.Tab>
              <Tabs.Tab value="standard">Standard events</Tabs.Tab>
              <Tabs.Tab value="interaction">Interaction events</Tabs.Tab>
              <Tabs.Tab value="conversion">Conversion events</Tabs.Tab>
              <Tabs.Tab value="e-commerce">E-commerce events</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="all" pt="xs">
              <EventList
                events={filteredEvents}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
              />
            </Tabs.Panel>

            <Tabs.Panel value="standard" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("standard")
                )}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
              />
            </Tabs.Panel>

            <Tabs.Panel value="interaction" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("interaction")
                )}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
              />
            </Tabs.Panel>

            <Tabs.Panel value="conversion" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("conversion")
                )}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
              />
            </Tabs.Panel>

            <Tabs.Panel value="e-commerce" pt="xs">
              <EventList
                events={filteredEvents.filter((event) =>
                  event.tags.includes("e-commerce")
                )}
                toggleVisibility={toggleVisibility}
                visibleEventId={visibleEventId}
                openImageModal={openImageModal}
                closeImageModal={closeImageModal}
                selectedImage={selectedImage}
              />
            </Tabs.Panel>

            <Tabs.Panel value="informations" pt="xs">
              <p>Informations du projet</p>
            </Tabs.Panel>
          </Tabs>
        </div>
      ) : (
        <div>
          <Tabs color="gray" value={selectedTab} onTabChange={setSelectedTab}>
            <Tabs.List>
              <Tabs.Tab value="informations">Informations du projet</Tabs.Tab>
              <Tabs.Tab value="all">All Events</Tabs.Tab>
              <Tabs.Tab value="standard">Standard events</Tabs.Tab>
              <Tabs.Tab value="interaction">Interaction events</Tabs.Tab>
              <Tabs.Tab value="conversion">Conversion events</Tabs.Tab>
              <Tabs.Tab value="e-commerce">E-commerce events</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="informations" pt="xs">
              <div className="container-grid">
                <Grid className="grid" grow gutter="sm">
                  <Grid.Col className="grid-col" span={3}>
                    <p className="title">Plateforme</p>
                    <p className="info">
                      {projectDetails?.platform_type || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={5}>
                    <p className="title">Url</p>
                    <p className="info">
                      {projectDetails?.projectUrl || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Secteur</p>
                    <p className="info">
                      {projectDetails?.activity_area || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Pays</p>
                    <p className="info">
                      {projectDetails?.country || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={1}>
                    <p className="title">Technologie</p>
                    <p className="info">
                      {projectDetails?.technology || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Analytics Plateforme</p>
                    <p className="info">
                      {projectDetails?.analytics_platform || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={2}>
                    <p className="title">Tag Manager</p>
                    <p className="info">
                      {projectDetails?.encapsulator || "Non disponible"}
                    </p>
                  </Grid.Col>
                  <Grid.Col className="grid-col" span={8}>
                    <p className="title">Informations</p>
                    <p className="infos">{projectDetails?.information || ""}</p>
                    {renderCustomFields()}
                  </Grid.Col>
                </Grid>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="all" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="standard" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event standard configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="interaction" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event interaction configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="conversion" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event conversion configuré
              </p>
            </Tabs.Panel>

            <Tabs.Panel value="e-commerce" pt="xs">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                Aucun event e-commerce configuré
              </p>
            </Tabs.Panel>
          </Tabs>
        </div>
      )}
    </div>
  );
};

const EventList = ({
  events,
  handleStatusChange,
  toggleVisibility,
  visibleEventId,
  openImageModal,
  closeImageModal,
  selectedImage,
  showAll,
  setShowAll,
}) => {
  const MAX_ROWS = 3;
  const [expandedEvents, setExpandedEvents] = useState({}); // État pour suivre l'expansion

  const toggleShowAll = (eventId) => {
    setExpandedEvents((prev) => ({
      ...prev,
      [eventId]: !prev[eventId],
    }));
  };
  return (
    <div className="container">
      {events.map((event) => (
        <div className="container-event" key={event.id}>
          <div className="title-event">
            <div className="container-event-title">
              <div>
                <div className="event">
                  {event.name} • {event.tags}
                </div>
                <div className="trigger">
                  Déclencheur : {event.trigger} - Description :{" "}
                  {event.description}
                </div>
              </div>
              <div className="status">
                <Select
                  value={event.status}
                  description="statut"
                  data={[
                    { value: "À configurer", label: "À configurer" },
                    { value: "À implementer", label: "À implementer" },
                    { value: "Implémenté", label: "Implémenté" },
                    { value: "À recetter", label: "À recetter" },
                    { value: "Recetté", label: "Recetté" },
                    { value: "Annulé", label: "Annulé" },
                  ]}
                  onChange={(value) => handleStatusChange(event.id, value)}
                />
              </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th className="th-name">Nom du paramètre</th>
                  <th className="th-type">Type</th>
                  <th className="th-value">Valeur par défaut</th>
                  <th className="th-comment">Commentaire</th>
                </tr>
              </thead>
              <tbody>
                {event.parameters && event.parameters.length > 0 ? (
                  event.parameters
                    .slice(
                      0,
                      expandedEvents[event.id]
                        ? event.parameters.length
                        : MAX_ROWS
                    )
                    .map((param) => (
                      <tr key={param.id}>
                        <td className="td-name">{param.parameterName}</td>
                        <td className="td-type">{param.datatype}</td>
                        <td className="td-value">{param.value}</td>
                        <td className="td-comment">
                          {param.parameterComments}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="4">Aucun paramètre</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="container-show-table">
              {event.parameters?.length > MAX_ROWS && (
                <div onClick={() => toggleShowAll(event.id)}>
                  {expandedEvents[event.id] ? (
                    <span>
                      Voir moins
                      <img src={less} alt="less" />
                    </span>
                  ) : (
                    <span>
                      Voir plus <img src={more} alt="more" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="container-datalayer-img">
            {event.datalayer && (
              <div className="container-datalayer">
                <Prism language="js" block>
                  {event.datalayer}
                </Prism>
              </div>
            )}
            {event.images?.length > 0 ? (
              <div className="container-screenshot">
                <h5>Captures d'écrans</h5>
                <div className="container-img">
                  {event.images.map((image, index) => (
                    <div className="div-img-mantine" key={image.id}>
                      <Image
                        radius="sm"
                        className="mantine-img"
                        src={image.file_path}
                        alt={`Event ${event.name}`}
                        onClick={() => openImageModal(image)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="container-no-screenshot">
                <h5>Captures d'écrans</h5>
                <p className="no-images-msg">
                  Aucune capture d'écrans disponible pour cet événement.
                </p>
              </div>
            )}
          </div>
          <div className="container-recipe">
            <div className="title-and-addrecipe">
              <h5>Recette</h5>
            </div>
            {event.recipe && event.recipe.length > 0 ? (
              <table className="table-recipe">
                <thead>
                  <tr>
                    <th className="th-date">Date</th>
                    <th className="th-status">Status</th>
                    <th>Commentaire</th>
                  </tr>
                </thead>
                <tbody>
                  {event.recipe.map((recipe) => (
                    <tr key={recipe.id}>
                      <td>{recipe.date}</td>
                      <td>
                        <Badge color={recipe.status === "KO" ? "red" : "green"}>
                          {recipe.status}
                        </Badge>
                      </td>
                      <td>{recipe.comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="no-recipe-msg">
                Aucune recette disponible pour cet événement.
              </p>
            )}
          </div>
          <Modal
            opened={!!selectedImage}
            size="auto"
            overlayOpacity={0.2}
            onClose={closeImageModal}
            centered
          >
            {selectedImage && (
              <Image
                radius="md"
                src={selectedImage.file_path}
                alt="capture d'écran"
              />
            )}
          </Modal>
          <div className="container-line">
            <div className="line">
              ____________________________________________________
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PublicTrackingPlan;
