import React, { useState, useEffect } from "react";
import instance from "../axios/global";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { ActionIcon, Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const GenericEvent = () => {
  const [allGenericEvents, setAllGenericEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const res = await instance.get("/event/generic");
        setAllGenericEvents(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchEvents();
  }, []);

  const handleOpenDeleteModal = (eventId) => {
    setEventIdToDelete(eventId);
    open();
  };

  const handleSubmitDeleteEvent = async () => {
    try {
      await instance.delete(`event/delete/${eventIdToDelete}`);
      setAllGenericEvents(
        allGenericEvents.filter((event) => event.id !== eventIdToDelete)
      );
      close();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement: ", error);
    }
  };

  const renderTable = (events, tag) => (
    <div key={tag}>
      <h3>{tag}</h3>
      <table>
        <thead>
          <tr>
            <th>Nom</th>
            {/* <th>Type</th> */}
            <th>Déclencheur</th>
            <th>Description</th>
            <th className="th-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr key={event.id}>
              <td>{event.name}</td>
              {/* <td>{event.type}</td> */}
              <td>{event.trigger}</td>
              <td>{event.description}</td>
              <td className="td-actions">
                <ActionIcon
                  onClick={() => navigate(`/edit-event/${event.id}`)}
                  variant="subtle"
                  color="gray"
                >
                  <IconPencil
                    style={{ width: 16, height: 16, cursor: "pointer" }}
                    stroke={1.5}
                  />
                </ActionIcon>
                <ActionIcon
                  onClick={() => handleOpenDeleteModal(event.id)}
                  variant="subtle"
                  color="red"
                >
                  <IconTrash
                    style={{ width: 16, height: 16, cursor: "pointer" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const tags = ["e-commerce", "standard", "conversion", "interaction"];
  const eventsByTag = tags.map((tag) => ({
    tag,
    events: allGenericEvents.filter((event) => event.tags.includes(tag)),
  }));

  return (
    <div className="generic-event">
      <h1>Liste events générique</h1>
      <div className="h2-btn">
        <h2>Retrouvez tous vos events réutilisable sur tout vos projets</h2>
        <Button
          variant="filled"
          color="indigo"
          size="xs"
          onClick={() => navigate(`/creer-event`)}
        >
          Créer un event générique
        </Button>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        eventsByTag.map(({ tag, events }) =>
          events.length > 0 ? renderTable(events, tag) : null
        )
      )}
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title="Supprimer mon projet"
      >
        <div className="modal">
          <p style={{ marginBottom: "2rem" }}>
            Êtes vous sur de vouloir supprimer votre projet ?
          </p>
          <div
            className="btn-div"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              className="cancel-btn"
              color="gray"
              onClick={close}
              style={{ marginRight: "1rem" }}
            >
              annuler
            </Button>
            <Button
              className="delete-btn"
              color="red"
              onClick={handleSubmitDeleteEvent}
            >
              supprimer
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GenericEvent;
