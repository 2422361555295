import React, { useState } from "react";
import {
  Button,
  Select,
  Image,
  Modal,
  TextInput,
  Badge,
  Textarea,
} from "@mantine/core";
import { Prism } from "@mantine/prism";
import { toast } from "sonner";
import instance from "../axios/global";
import trash from "../assets/trash.svg";

const EventList = ({
  events,
  handleStatusChange,
  toggleVisibility,
  visibleEventId,
  openImageModal,
  closeImageModal,
  selectedImage,
  showAll,
  setShowAll,
  setProjectEvents,
}) => {
  const MAX_ROWS = 3;
  const [expandedEvents, setExpandedEvents] = useState({});
  const [recipeModalOpen, setRecipeModalOpen] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [recipeToDelete, setRecipeToDelete] = useState(null);
  const [newRecipeData, setNewRecipeData] = useState({
    date: "",
    status: "",
    comment: "",
  });

  const openRecipeModal = (eventId) => {
    setCurrentEventId(eventId); // Définit l'ID de l'événement pour lequel on ajoute une recette
    setRecipeModalOpen(true);
  };

  const closeRecipeModal = () => {
    setRecipeModalOpen(false);
    setCurrentEventId(null); // Réinitialise l'événement courant
    setNewRecipeData({ date: "", status: "", comment: "" }); // Réinitialise les données du formulaire
  };

  const openDeleteModal = (eventId, recipeId) => {
    console.log("Event ID:", eventId, "Recipe ID:", recipeId); // Inspectez les valeurs ici
    setRecipeToDelete({ eventId, recipeId });
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setRecipeToDelete(null);
  };

  const toggleShowAll = (eventId) => {
    setExpandedEvents((prev) => ({
      ...prev,
      [eventId]: !prev[eventId],
    }));
  };

  const handleAddRecipe = async () => {
    if (!currentEventId) return;

    // Obtenir la date actuelle
    const currentDate = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD

    try {
      const newRecipe = {
        ...newRecipeData,
        date: currentDate, // Ajouter la date actuelle
      };

      await instance.put(`/event/addRecipe/${currentEventId}`, newRecipe);
      toast.success("Recette ajoutée avec succès !");
      setProjectEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === currentEventId
            ? { ...event, recipe: [...(event.recipe || []), newRecipe] }
            : event
        )
      );
      closeRecipeModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la recette :", error);
      toast.error("Erreur lors de l'ajout de la recette.");
    }
  };

  const confirmDeleteRecipe = async () => {
    if (!recipeToDelete || !recipeToDelete.recipeId) {
      toast.error("Impossible de supprimer : Recipe ID est manquant.");
      return;
    }

    const { eventId, recipeId } = recipeToDelete;

    try {
      await instance.put(`/event/removeRecipe/${eventId}`, { recipeId });
      toast.success("Recette supprimée avec succès !");
      setProjectEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId
            ? {
                ...event,
                recipe: event.recipe.filter((recipe) => recipe.id !== recipeId),
              }
            : event
        )
      );
      closeDeleteModal();
    } catch (error) {
      console.error("Erreur lors de la suppression de la recette :", error);
      toast.error("Erreur lors de la suppression de la recette.");
    }
  };

  return (
    <div className="container">
      {events.map((event) => (
        <div className="container-event" key={event.id}>
          <div className="title-event">
            <div className="container-event-title">
              <div>
                <div className="event">
                  {event.name} • {event.tags}
                </div>
                <div className="trigger">
                  Déclencheur : {event.trigger} - Description :{" "}
                  {event.description}
                </div>
              </div>
              <div className="status">
                <Select
                  value={event.status}
                  description="statut"
                  color="indigo"
                  className="select-statut"
                  data={[
                    { value: "À configurer", label: "À configurer" },
                    { value: "À implementer", label: "À implementer" },
                    { value: "Implémenté", label: "Implémenté" },
                    { value: "À recetter", label: "À recetter" },
                    { value: "Recetté", label: "Recetté" },
                    { value: "Annulé", label: "Annulé" },
                  ]}
                  onChange={(value) => handleStatusChange(event.id, value)}
                />
              </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th className="th-name">Nom du paramètre</th>
                  <th className="th-type">Type</th>
                  <th className="th-value">Valeur par défaut</th>
                  <th className="th-comment">Commentaire</th>
                </tr>
              </thead>
              <tbody>
                {event.parameters && event.parameters.length > 0 ? (
                  event.parameters
                    .slice(
                      0,
                      expandedEvents[event.id]
                        ? event.parameters.length
                        : MAX_ROWS
                    )
                    .map((param) => (
                      <tr key={param.id}>
                        <td className="td-name">{param.parameterName}</td>
                        <td className="td-type">{param.datatype}</td>
                        <td className="td-value">{param.value}</td>
                        <td className="td-comment">
                          {param.parameterComments}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="4">Aucun paramètre</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="container-show-table">
              {event.parameters?.length > MAX_ROWS && (
                <div onClick={() => toggleShowAll(event.id)}>
                  {expandedEvents[event.id] ? (
                    <span>Voir moins</span>
                  ) : (
                    <span>Voir plus</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="container-datalayer-img">
            {event.datalayer && (
              <div className="container-datalayer">
                <Prism language="js" block>
                  {event.datalayer}
                </Prism>
              </div>
            )}
            {event.images?.length > 0 ? (
              <div className="container-screenshot">
                <h5>Captures d'écrans</h5>
                <div className="container-img">
                  {event.images.map((image, index) => (
                    <div className="div-img-mantine" key={image.id}>
                      <Image
                        radius="sm"
                        className="mantine-img"
                        src={image.file_path}
                        alt={`Event ${event.name}`}
                        onClick={() => openImageModal(image)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="container-no-screenshot">
                <h5>Captures d'écrans</h5>
                <p className="no-images-msg">
                  Aucune capture d'écrans disponible pour cet événement.
                </p>
              </div>
            )}
          </div>

          <div className="container-recipe">
            <div className="title-and-addrecipe">
              <h5>Recette</h5>
              <Button
                size="xs"
                color="indigo"
                onClick={() => openRecipeModal(event.id)}
              >
                Ajouter une recette
              </Button>
            </div>
            {event.recipe && event.recipe.length > 0 ? (
              <table className="table-recipe">
                <thead>
                  <tr>
                    <th className="th-date">Date</th>
                    <th className="th-status">Status</th>
                    <th>Commentaire</th>
                    <th className="th-actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {event.recipe.map((recipe) => (
                    <tr key={recipe.id}>
                      <td>{recipe.date}</td>
                      <td>
                        <Badge color={recipe.status === "KO" ? "red" : "green"}>
                          {recipe.status}
                        </Badge>
                      </td>
                      <td>{recipe.comment}</td>
                      <td>
                        <img
                          src={trash}
                          alt="delete"
                          onClick={() => openDeleteModal(event.id, recipe.id)}
                        ></img>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="no-recipe-msg">
                Aucune recette disponible pour cet événement.
              </p>
            )}
            <Modal
              opened={recipeModalOpen}
              onClose={closeRecipeModal}
              title="Ajouter une nouvelle recette"
              centered
              overlayOpacity={0.2}
            >
              <div className="container-add-recipe">
                <Select
                  withAsterisk
                  //   style={{
                  //     color: "white",
                  //     border: "1px solid #373F4F",
                  //     backgroundColor: "#222838",
                  //   }}
                  label="Statut"
                  type="text"
                  value={newRecipeData.status}
                  data={[
                    { value: "KO", label: "KO" },
                    { value: "OK", label: "OK" },
                  ]}
                  onChange={(value) =>
                    setNewRecipeData({
                      ...newRecipeData,
                      status: value,
                    })
                  }
                  placeholder="Ajoutez votre statut"
                />
                <Textarea
                  withAsterisk
                  className="text-input"
                  label="Ajoutez un commentaire"
                  value={newRecipeData.comment}
                  onChange={(e) =>
                    setNewRecipeData({
                      ...newRecipeData,
                      comment: e.target.value,
                    })
                  }
                  placeholder="Commentaire"
                />
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button color="indigo" size="xs" onClick={handleAddRecipe}>
                    Ajouter
                  </Button>
                  <Button
                    color="gray"
                    size="xs"
                    onClick={closeRecipeModal}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    Annuler
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              opened={deleteModalOpen}
              onClose={closeDeleteModal}
              overlayOpacity={0.2}
              title="Confirmation de suppression"
              centered
            >
              <p>Êtes-vous sûr de vouloir supprimer cette recette ?</p>
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button color="red" onClick={confirmDeleteRecipe}>
                  Oui, supprimer
                </Button>
                <Button
                  style={{ marginLeft: "1rem" }}
                  onClick={closeDeleteModal}
                >
                  Annuler
                </Button>
              </div>
            </Modal>
            <Modal
              opened={!!selectedImage}
              size="auto"
              overlayOpacity={0.2}
              onClose={closeImageModal}
              centered
            >
              {selectedImage && (
                <Image
                  radius="md"
                  src={selectedImage.file_path}
                  alt="capture d'écran"
                />
              )}
            </Modal>
          </div>
          <div className="container-line">
            <div className="line">
              ____________________________________________________
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventList;
