import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import instance from "../axios/global";
import {
  IconCopyPlus,
  IconTrash,
  IconSettingsAutomation,
  IconDotsVertical,
} from "@tabler/icons-react";
import { Modal, Chip, Menu, Button, Table, Badge } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import up from "../assets/up.svg";
import down from "../assets/down.svg";
import inprocess from "../assets/inprocess.svg";
import toconfig from "../assets/to-configure.svg";
import inwaiting from "../assets/inwaiting.svg";

const Project = () => {
  const { projectId } = useParams();
  const [allGenericEvents, setAllGenericEvents] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectEvents, setProjectEvents] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [opened, { close, open }] = useDisclosure(false);
  const [opened2, { close: close2, open: open2 }] = useDisclosure(false);
  const [loading, setLoading] = useState(true);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const navigate = useNavigate();

  const fetchProjectDetails = async () => {
    try {
      setLoading(true);
      const res = await instance.get(`/project/${projectId}`);
      setProjectDetails(res.data);
      setLoading(false);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails du projet",
        error
      );
      setLoading(false);
    }
  };

  const fetchProjectEvents = async () => {
    try {
      setLoading(true);
      const res = await instance.get(`/event/projectEvent/${projectId}`);
      setProjectEvents(res.data || []); // Ensure it defaults to an empty array
      setLoading(false);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des events du projet",
        error
      );
      setLoading(false);
    }
  };

  const fetchGenericEvents = async () => {
    try {
      setLoading(true);
      const res = await instance.get("/event/generic");
      setAllGenericEvents(res.data || []); // Ensure it defaults to an empty array
      setLoading(false);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des événements génériques",
        error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGenericEvents();
    fetchProjectDetails();
    fetchProjectEvents();
  }, [projectId]);

  const handleOpenModal = () => open2();
  const handleOpenDeleteModal = (eventId) => {
    setEventIdToDelete(eventId);
    open();
  };

  const handleSubmitDeleteEvent = async () => {
    try {
      if (eventIdToDelete) {
        await instance.delete(`event/delete/${eventIdToDelete}`);
        setProjectEvents(
          projectEvents.filter((event) => event.id !== eventIdToDelete)
        );
        fetchProjectEvents();
      }
      close();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement: ", error);
    }
  };

  const handleChipClick = (chipId) => {
    setSelectedChips((prevSelectedChips) => {
      if (prevSelectedChips.includes(chipId)) {
        return prevSelectedChips.filter((id) => id !== chipId);
      } else {
        return [...prevSelectedChips, chipId];
      }
    });
  };

  const handleSubmitGenericEvent = async () => {
    try {
      for (const selectedChipId of selectedChips) {
        const addEvent = await instance.post(
          `event/duplicate/${selectedChipId}`,
          { projectId }
        );
        const duplicatedEvent = addEvent.data;
        await instance.put(
          `/event/updateStatus/${duplicatedEvent.newEventId}`,
          { status: "À configurer" }
        );
      }
      fetchProjectEvents();
      close2();
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'événement :", error);
    }
  };

  const handleDuplicateEvent = async (eventId) => {
    try {
      const response = await instance.post(`/event/duplicate/${eventId}`, {
        projectId,
      });
      const duplicatedEvent = response.data;
      duplicatedEvent.name += "_copy";
      await instance.put(`/event/updateStatus/${duplicatedEvent.newEventId}`, {
        status: "À configurer",
      });
      fetchProjectEvents();
    } catch (error) {
      console.error("Erreur lors de la duplication de l'événement :", error);
    }
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const ProjectStatus = ({ status }) => {
    // Définir un mapping pour associer chaque statut à une image
    const statusToImageMap = {
      Configuré: up,
      Cloturé: down,
      "En cours d'implementation": inprocess,
      "À configurer": toconfig,
      "En attente": inwaiting,
    };

    // Déterminer l'image à afficher en fonction du statut
    const imageSrc = statusToImageMap[status];

    // Définir une classe CSS en fonction du statut (facultatif)
    let statusClass = "";
    switch (status) {
      case "En cours d'implementation":
        statusClass = "status-en-cours";
        break;
      case "Configuré":
        statusClass = "status-configure";
        break;
      case "À configurer":
        statusClass = "status-to-configure";
        break;
      case "Cloturé":
        statusClass = "status-cloture";
        break;
      case "En attente":
        statusClass = "status-en-attente";
        break;
      default:
        statusClass = "";
    }

    return (
      <div className={`status-container ${statusClass}`}>
        <img src={imageSrc} alt={`${status} icon`} className="status-icon" />
        <div style={{ marginLeft: "14px" }}>
          <h1>
            {projectDetails?.projectName || "Nom du projet non disponible"}
          </h1>
          <div className="status-updateat">
            <p className={statusClass}>{status}</p>
            <h4>
              Dernière modification le {formatDate(projectDetails?.updatedAt)}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const groupEventsByTag = (events) => {
    return events.reduce((groupedEvents, event) => {
      const tags = event.tags ? event.tags.split(",") : ["Sans tag"];
      tags.forEach((tag) => {
        if (!groupedEvents[tag]) {
          groupedEvents[tag] = [];
        }
        groupedEvents[tag].push(event);
      });
      return groupedEvents;
    }, {});
  };

  const groupedEvents = groupEventsByTag(allGenericEvents);

  return (
    <div className="container-page-project">
      <div className="page-projects">
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          <div>
            <div className="container-project-info">
              <span>
                <ProjectStatus status={projectDetails?.status} />
              </span>

              {/* <div className='container-grid'>
                                <Grid className='grid' grow gutter="sm">
                                    <Grid.Col className='grid-col' span={3}>
                                        <p className='title'>Plateforme</p>
                                        <p className='info'>{projectDetails?.platform_type || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={5}>
                                        <p className='title'>Url</p>
                                        <p className='info'>{projectDetails?.projectUrl || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={2}>
                                        <p className='title'>Secteur</p>
                                        <p className='info'>{projectDetails?.activity_area || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={2}>
                                        <p className='title'>Pays</p>
                                        <p className='info'>{projectDetails?.country || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={2}>
                                        <p className='title'>Technologie</p>
                                        <p className='info'>{projectDetails?.technology || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={3}>
                                        <p className='title'>Analytics Plateforme</p>
                                        <p className='info'>{projectDetails?.analytics_platform || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={5}>
                                        <p className='title'>Encapsuleur</p>
                                        <p className='info'>{projectDetails?.encapsulator || "Non disponible"}</p>
                                    </Grid.Col>
                                    <Grid.Col className='grid-col' span={6}>
                                        <p className='title'>Informations</p>
                                        <p className='info'>{projectDetails?.information || "Non disponible"}</p>
                                    </Grid.Col>
                                </Grid>
                         </div> */}
            </div>
            <div className="container-events">
              <div className="container-title-button">
                <div>
                  <h3>Evenements</h3>
                  <h4>Tous les evenements du projet</h4>
                </div>
                <div>
                  <Button
                    color="indigo"
                    className="btn-add"
                    size="xs"
                    onClick={() =>
                      navigate(
                        `/project/${projectDetails?.id}/add-custom-event`
                      )
                    }
                  >
                    Ajouter un event custom
                  </Button>
                  <Button
                    color="indigo"
                    className="btn-add"
                    size="xs"
                    onClick={handleOpenModal}
                  >
                    Ajouter un event generique
                  </Button>
                </div>
              </div>
              {loading ? (
                <p>Chargement des événements en cours...</p>
              ) : projectEvents.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th className="th-name">Nom</th>
                      {/* <td>Type</td> */}
                      <th className="th-trigger">Déclencheur</th>
                      <th className="th-comment">Commentaire</th>
                      <th className="th-priority">Priorité</th>
                      <th className="th-status">Statut</th>
                      <th className="th-actions"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectEvents.map((event) => (
                      <tr key={event.id}>
                        <td className="td-name">{event.name}</td>
                        {/* <td>{event.type}</td> */}
                        <td className="td-trigger">{event.trigger}</td>
                        <td className="td-comment">{event.description}</td>
                        <td>
                          {event.priority ? (
                            <Badge
                              size="sm"
                              color={
                                event.priority === "Haute"
                                  ? "red"
                                  : event.priority === "Moyenne"
                                  ? "orange"
                                  : "yellow"
                              }
                            >
                              {event.priority}
                            </Badge>
                          ) : (
                            <span></span> // Message par défaut si la priorité est vide
                          )}
                        </td>
                        <td className="td-status">
                          {event.status || "Non disponible"}
                        </td>
                        <td>
                          <Menu
                            shadow="md"
                            width={200}
                            style={{ cursor: "pointer" }}
                          >
                            <Menu.Target>
                              <IconDotsVertical size={14} />
                            </Menu.Target>

                            <Menu.Dropdown>
                              <Menu.Label>Actions</Menu.Label>
                              <Menu.Item
                                icon={<IconCopyPlus size={14} />}
                                onClick={() => handleDuplicateEvent(event.id)}
                              >
                                Dupliquer
                              </Menu.Item>
                              <Menu.Item
                                onClick={() =>
                                  navigate(
                                    `/project/${projectId}/configurer-event/${event.id}`
                                  )
                                }
                                icon={<IconSettingsAutomation size={14} />}
                              >
                                Configurer
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => handleOpenDeleteModal(event.id)}
                                icon={<IconTrash size={14} color="red" />}
                              >
                                Supprimer
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>pas d'event pour le moment</div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title="Supprimer mon event"
      >
        <div className="modal">
          <p style={{ marginBottom: "2rem", fontSize: "0.875rem" }}>
            Êtes vous sur de vouloir supprimer votre event ?
          </p>
          <div
            className="btn-div"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              className="cancel-btn"
              color="gray"
              onClick={close}
              style={{ marginRight: "1rem" }}
            >
              annuler
            </Button>
            <Button
              className="delete-btn"
              color="red"
              onClick={handleSubmitDeleteEvent}
            >
              supprimer
            </Button>
          </div>
        </div>
      </Modal>
      <Modal opened={opened2} onClose={close2} size="xl">
        <h3 className="title-standard">Standard</h3>
        <div className="chip-container">
          {groupedEvents["standard"]?.map((event) => (
            <Chip
              className="chip"
              style={{ width: "120px" }}
              defaultChecked
              radius="sm"
              size="md"
              color="#5A63D3"
              key={event.id}
              value={event.id}
              checked={selectedChips.includes(event.id)}
              onChange={() => handleChipClick(event.id)}
            >
              {event.name}
            </Chip>
          ))}
        </div>
        <h3 className="title-tag">E-Commerce</h3>
        <div className="chip-container">
          {groupedEvents["e-commerce"]?.map((event) => (
            <Chip
              className="chip"
              style={{ width: "120px" }}
              defaultChecked
              radius="sm"
              size="md"
              color="#5A63D3"
              key={event.id}
              value={event.id}
              checked={selectedChips.includes(event.id)}
              onChange={() => handleChipClick(event.id)}
            >
              {event.name}
            </Chip>
          ))}
        </div>
        <h3 className="title-tag">Conversion</h3>
        <div className="chip-container">
          {groupedEvents["conversion"]?.map((event) => (
            <Chip
              className="chip"
              style={{ width: "120px" }}
              defaultChecked
              radius="sm"
              size="md"
              color="#5A63D3"
              key={event.id}
              value={event.id}
              checked={selectedChips.includes(event.id)}
              onChange={() => handleChipClick(event.id)}
            >
              {event.name}
            </Chip>
          ))}
        </div>
        <h3 className="title-tag">Interaction</h3>
        <div className="chip-container">
          {groupedEvents["interaction"]?.map((event) => (
            <Chip
              className="chip"
              style={{ width: "120px" }}
              defaultChecked
              radius="sm"
              size="md"
              color="#5A63D3"
              key={event.id}
              value={event.id}
              checked={selectedChips.includes(event.id)}
              onChange={() => handleChipClick(event.id)}
            >
              {event.name}
            </Chip>
          ))}
        </div>
        <div
          className="btn-div"
          style={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            className="cancel-btn"
            color="gray"
            style={{ marginRight: "1rem" }}
            onClick={close2}
          >
            annuler
          </Button>
          <Button
            style={{ background: "#5B63D3" }}
            className="delete-btn"
            onClick={handleSubmitGenericEvent}
          >
            ajouter
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Project;
